import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Hero from "../components/Sections/BasicHero"
import Column from "../components/Sections/TwoColumn"
import Donation from "../components/Sections/DonationTop"
import DonationBottom from "../components/Sections/DonationBottom"
import Social from "../components/Sections/SocialFeed"
import Subscription from "../components/Sections/Subscription"
import Impact from "../components/Sections/Impact"
import { MissionImages } from "../Hooks/MissionImages"

const Mission = () => {
  const { hero, carePackage, education, events } = MissionImages()
  return (
    <Layout>
      <SEO
        title="My Mission - K.A.T.E"
        description="Keeping All Troops Encouraged (K.A.T.E.). Supporting our troops with care packages, events and education. Learn more and help further our mission."
      />
      <Hero
        fluid={hero.fluid}
        first={true}
        margin="0 0 0 auto"
        subtitle="MY MISSION"
        title="KEEPING ALL TROOPS ENCOURAGED (K.A.T.E)"
        body="My passion has always been to give back to soldiers and veterans. With a very strong military based family and friends, I have always made it my priority to never let a soldier feel forgotten. A simple thank you, giving small care packages, and attending events are just a few of the ways I show them my gratitude. I plan to extend my platform even further to have people of all ages showing their appreciation towards each soldier and veteran."
        cta={<></>}
      />
      <Column
        fluid={carePackage.fluid}
        link="/mission#donate"
        buttonText="Make a Donation"
        first={true}
        reverse={true}
        head="Care Packages"
        body="Care packages are a great way to show your appreciation while providing practical help. I collect nonperishable items to send to soldiers and encourage others to do the same. You could create your own care package, or follow my care package guide below! "
      />
      <Column
        fluid={education.fluid}
        link="/education"
        buttonText="Find Out More"
        reverse={false}
        head="Saying Thank You"
        body="One of my favorite ways to support soldiers and veterans is to write simple but meaningful thank you cards. Here are a few ideas of what to include and how to give them out. Additionally, check out my fun coloring pages to give children a fun gift to create and give away. "
      />
      <Column
        fluid={events.fluid}
        link="/events"
        buttonText="Upcoming Events"
        reverse={true}
        head="Events"
        body="Volunteering at events is one of the best ways to show your appreciation. Click the link to see the upcoming events supporting our soldiers and veterans."
      />
      <Donation id="donate" />
      <DonationBottom />
      <Impact />
      <Subscription />
      <Social />
    </Layout>
  )
}

export default Mission
