import React from "react"
import { styled } from "linaria/react"
import iconSent from "../../images/Icons/MissionImpact/Icon-Sent.svg"
import iconReceived from "../../images/Icons/MissionImpact/Icon-Received.svg"
import iconArrived from "../../images/Icons/MissionImpact/Icon-Arrived.svg"

const Divider = styled.div`
  @media screen and (max-width: 768px) {
    height: 50px;
    width: 1px;
  }
  height: 1px;
  max-width: 100px;
  width: 100%;
  margin: auto;
  background: #ccc;
`

const Wrapper = styled.section`
  @media screen and (max-width: 768px) {
    margin: 100px 0 100px 0;
    padding: 0 15px;
    > ${Divider} {
      height: 1px;
      max-width: 100px;
      width: 100%;
      margin: auto;
      background: #ccc;
    }
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 250px;
  margin-bottom: 250px;
  h2 {
    @media screen and (max-width: 480px) {
      font-size: 35px;
    }
    margin: auto;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 20px;
  }
  p {
    @media screen and (max-width: 480px) {
      font-size: 14px;
      font-weight: 300;
    }
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    margin: 50px auto;
    margin-top: 20px;
    max-width: 650px;
  }
`

const Container = styled.div`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > * {
      margin: 0 0 30px 0;
    }
    > *:last-child {
      margin: 0 0 0 0;
    }
  }
  margin-top: 50px;
  display: flex;
  width: 100%;
  padding: 0 55px;
  align-items: center;
  justify-content: center;
`

const Item = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 15px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  span {
    text-align: center;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
  }
`

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`
const Impact = () => {
  return (
    <Wrapper>
      <h2>See Your Impact</h2>
      <Divider />
      <p>
        If you include your email and name, I will update you about items that
        have been received and when they are shipped off to soldiers.
      </p>
      <Container>
        <Item>
          <StyledImage
            src={iconReceived}
            alt="Drawing of a box with inward facing arrow"
          />
          <span>Items Received</span>
        </Item>
        <Divider />
        <Item>
          <StyledImage
            src={iconSent}
            alt="Drawing of a paper airplane flying through the air"
          />
          <span>Items Sent</span>
        </Item>
        <Divider />
        <Item>
          <StyledImage
            src={iconArrived}
            alt="Drawing of an opened box with heart above the opening"
          />
          <span>Package Arrived</span>
        </Item>
      </Container>
    </Wrapper>
  )
}

export default Impact
