import React, { useState, useEffect } from "react"
import { styled } from "linaria/react"
import { colors } from "../theme"

const Button = styled.button`
  @media screen and (max-width: 480px) {
    padding: 10px 40px;
  }
  margin: ${props => props.margin || "default"};
  width: max-content;
  border-radius: 1px;
  background-color: ${props => props.bgColor || colors.black};
  padding: 15px 60px;
  border: ${props => props.border || "none"};
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
  margin: auto;
`
const Form = styled.form`
  @media screen and (max-width: 900px) {
    position: relative;
    padding: 15px;
    font-size: 14px;
    max-width: none;
    top: 0;
    right: 0;
  }
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0 40px 20px;
  max-width: 50%;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  > * {
    margin-bottom: 15px;
  }
  > h3 {
    @media screen and (max-width: 480px) {
      padding: 20px 0 30px 0;
    }
    font-size: 35px;
    margin: auto;
    padding: 30px 0;
    font-weight: 500;
    text-transform: uppercase;
  }
  input {
    padding: 10px;
    border-radius: 8px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  input:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 1pt #f0c0d7;
  }
  textarea {
    font-weight: 500;
    resize: vertical;
    height: 250px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  textarea:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 1pt #f0c0d7;
  }
  div > span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 35px;
    /* or 219% */
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }
`

const Horiz = styled.div`
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
  width: 100%;
  display: flex;
  > input {
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
    width: 50%;
  }
  input:nth-child(2) {
    @media screen and (min-width: 1200px) {
      margin-right: 15px;
    }
  }
`

const Result = styled.span`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 32px;
`

// COMPONENT
const ContactForm = props => {
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    body: "",
  })
  const states = ["default", "sending", "success", "error"]
  const [status, setStatus] = useState(states[0])
  const [text, setText] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    setStatus(states[1])
    let res = await fetch("https://nocturnaldevelopment.org/submit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    }).catch(err => {
      setStatus(states[3])
      setText(
        "Submission timed out. Try again later or send an email to the address below."
      )
    })

    if (!res.ok) {
      setStatus(states[3])
      setText("Something went wrong. Check the information you submitted.")
      res.text().then(data => console.log(data))
      throw new Error(`HTTP Error ${res.status}`)
    } else {
      setStatus(states[2])
      setText("Success! I will get back with you shortly!")
      setState({
        name: "",
        email: "",
        subject: "",
        body: "",
      })
      res.text().then(data => console.log(data))
    }
  }
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value.toLowerCase() })
  }

  const buttonText = status => {
    let text
    switch (status) {
      case "success":
        text = "Thanks!"
        break
      case "sending":
        text = "Sending"
        break
      default:
        text = "Submit"
        break
    }
    return text
  }

  const statusTextStyle = status => {
    let style
    switch (status) {
      case "success":
        style = { opacity: "100" }
        break
      case "error":
        style = { opacity: "100" }
        break
      default:
        style = { display: "none" }
        break
    }
    return style
  }

  const resetStatus = () => {
    setTimeout(() => {
      setStatus(states[0])
      setText("")
    }, 7000)
  }

  useEffect(() => {
    if (status !== "default" && status !== "sending") {
      resetStatus()
    }
  }, [status])

  return (
    <Form name="contact" method="post" onSubmit={e => handleSubmit(e)}>
      <h3>Questions?</h3>
      <Horiz>
        <input type="hidden" name="form-name" value="contact" />
        <input
          type="text"
          aria-label="Name"
          value={state.name}
          placeholder="Name"
          name="name"
          onChange={e => handleChange(e)}
        />
        <input
          type="email"
          aria-label="Email"
          value={state.email}
          placeholder="Email"
          name="email"
          required
          onChange={e => handleChange(e)}
        />
      </Horiz>
      <input
        type="text"
        aria-label="Subject"
        value={state.subject}
        placeholder="Subject"
        name="subject"
        required
        onChange={e => handleChange(e)}
      />
      <textarea
        type="text"
        aria-label="Body"
        value={state.body}
        placeholder="Body"
        name="body"
        required
        onChange={e => handleChange(e)}
      />
      <Result style={statusTextStyle(status)}>{text}</Result>
      <Button type="submit" margin="auto" style={{ marginBottom: "0" }}>
        {buttonText(status)}
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          margin: "10px auto",
        }}
      >
        <span>Or Send an Email To: </span>
        <span> Kate@katelynFedrick.com </span>
      </div>
    </Form>
  )
}

export default ContactForm
