import { useStaticQuery, graphql } from "gatsby"

export const MissionImages = () => {
  const { contentfulMissionImages } = useStaticQuery(
    graphql`
      query {
        contentfulMissionImages {
          hero {
            title
            fluid(maxWidth: 1440) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          carePackage {
            title
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          education {
            title
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          events {
            title
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    `
  )
  return contentfulMissionImages
}
