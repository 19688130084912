import React from "react"
import { styled } from "linaria/react"
import { colors } from "../theme"
import Form from "../Elements/ContactForm"

const Wrapper = styled.section`
  @media screen and (max-width: 1280px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 100px;
    padding: 0;
    flex-direction: column;
  }
  margin-bottom: 250px;
  padding: 0 55px;
  display: flex;
  position: relative;
  background: ${colors.black};
`

const TextWrapper = styled.div`
  @media screen and (max-width: 1280px) {
    margin-left: 25px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 0;
    padding: 20px 15px;
    max-width: none;
  }
  padding-top: 80px;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  color: #fff;
  > h2 {
    @media screen and (max-width: 480px) {
      font-size: 30px;
      margin-bottom: 15px;
    }
    font-size: 35px;
    font-weight: 500;
    text-transform: uppercase;
  }
  > h3 {
    @media screen and (max-width: 480px) {
      font-size: 24px;
      margin-bottom: 15px;
    }
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
  }
  > p {
    @media screen and (max-width: 480px) {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 50px;
    }
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 70px;
  }
  > span {
    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
  }
`

const DonationBottom = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <h2>How to send</h2>
        <p>
          Carefully package all non perishable food items into a box and make
          sure to include your thank you card with who it is from. When you fill
          your box with your desired items, create a label with the address
          below!
        </p>
        <h3>Mailing Address</h3>
        <p>
          124 Perimeter Park Rd
          <br />
          Unit 102
          <br />
          Knoxville , TN 37922
          <br />
          United States
        </p>
      </TextWrapper>
      <Form />
    </Wrapper>
  )
}

export default DonationBottom
