import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { styled } from "linaria/react"
import { colors } from "../theme"
import Img from "gatsby-image"
import List from "../../images/care-package-list.pdf"

const Wrapper = styled.section`
  @media screen and (max-width: 900px) {
    margin: 100px 0 0 0;
  }
  margin: 250px 0 0 0;
  display: flex;
  flex-direction: column;
`

const IntroWrapper = styled.div`
  position: relative;
  background: ${colors.mainPink};
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 5;
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 50px;
`
const ImageWrapper = styled.div`
  @media screen and (max-width: 1000px) {
    display: none;
  }
  > * {
    pointer-events: none;
  }
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 45%;
  height: auto;
`

const TextContainer = styled.div`
  @media screen and (max-width: 1280px) {
    margin-right: auto;
    margin-top: 25px;
    margin-left: 25px;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 0;
    padding: 35px 15px;
  }
  width: 50%;
  margin-left: 55px;
  margin-top: 65px;
  margin-right: auto;
  span {
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 10px;
  }
  h2 {
    @media screen and (max-width: 480px) {
      font-size: 30px;
    }
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 20px;
    text-transform: uppercase;
    b {
      font-weight: 600;
    }
  }
  p {
    @media screen and (max-width: 480px) {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 28px;
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 55px;
    font-weight: 300;
    width: 90%;
  }
  input {
    height: 50px;
    max-width: 315px;
    width: 75%;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    padding: 10px;
  }
  input:focus {
    border-radius: 0;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    max-width: 220px;
    border-radius: 0;
    text-transform: uppercase;
    border: none;
    background-color: ${colors.black};
    > a {
      color: #fff;
      font-size: 20px;
      text-decoration: none;
      font-weight: 300;
    }
  }
`

const DonationTop = props => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "donate.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 740) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Wrapper id={props.id}>
      <IntroWrapper>
        <ImageWrapper>
          <Img
            imgStyle={{ objectFit: "cover" }}
            fluid={data.file.childImageSharp.fluid}
          />
        </ImageWrapper>
        <TextContainer>
          <span>Ready to Help?</span>
          <h2>Making a Donation</h2>
          <p>
            Care packages are a great way to show your support and help soldiers
            deployed overseas. Great items to send include candies, small games
            or crossword puzzles as well as any non-perishable food items. View
            the full list below for more ideas on what to send. When you are
            ready to get started, follow the directions below and don’t be
            afraid to reach out with any questions.
          </p>
          <div>
            <a href={List}>View Full List</a>
          </div>
        </TextContainer>
      </IntroWrapper>
    </Wrapper>
  )
}

export default DonationTop
